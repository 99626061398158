// JS dependency
var datepicker = require('jquery-ui/ui/widgets/datepicker');
var lang = $('body').attr('data-lang');
if(lang=='fr'){
  var datepicker_fr = require('jquery-ui/ui/i18n/datepicker-fr.js');
}

// CSS dependencies
import '/../node_modules/jquery-ui/themes/base/theme.css';
import '/../node_modules/jquery-ui/themes/base/datepicker.css';

// Input Start
$("#datepicker").datepicker({
  regional: lang,
  showOn: "both",
  buttonText: "<i class='material-icons'>calendar_today</i>",
  //buttonImage: 'theme/images/icons/gmap-marker.png',
  buttonImageOnly: false,
  defaultDate: "+1w",
  numberOfMonths: 1,
  minDate: 0,
  maxDate: '1Y',
});
