// JS dependency
var slick = require('slick-carousel');

// CSS dependency
import '/../node_modules/slick-carousel/slick/slick.css';

/*// Example
$('#slick').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: true,
  fade: false,
  arrows: true,
  autoplay: false,
  adaptiveHeight: true,
  cssEase: 'ease-in-out',
  speed: 400,
  autoplaySpeed: 6000,
  appendArrows: '#slick-arrows',
  prevArrow: '<button class="arrow-prev"><i class="material-icons">arrow_backward</i></button>',
  nextArrow: '<button class="arrow-next"><i class="material-icons">arrow_forward</i></button>',
  responsive: [{
      breakpoint: 1200,
      settings: {}
    },
    {
      breakpoint: 991,
      settings: {}
    },
    {
      breakpoint: 576,
      settings: {}
    },
  ]
});

// Example animations
setTimeout(function() {
  $('#slick .slick-active').addClass('animation');
}, 250);

$('#slick').on('afterChange', function(event, slick, currentSlide) {
  $('#slick .slick-active').addClass('animation');
});

$('#slick').on('beforeChange', function(event, slick, currentSlide) {
  $('#slick .slick-active').removeClass('animation');
});*/


$('#themes').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    fade: true,
    arrows: false,
    autoplay: true,
    adaptiveHeight: false,
    cssEase: 'ease-in-out',
    speed: 200,
    autoplaySpeed: 4000,
    responsive: [{
        breakpoint: 1200,
        settings: {}
    },
        {
            breakpoint: 991,
            settings: {}
        },
        {
            breakpoint: 576,
            settings: {}
        },
    ]
});
